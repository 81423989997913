.custom-datepicker {
    width: 200px;  /* Adjust the width as needed */
    height: 40px;  /* Adjust the height as needed */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white; /* Change background color if needed */
    z-index: 9999;  /* Set z-index to make sure the datepicker is on top */
  }
  
  .react-datepicker {
    background-color: white;  /* Solid background for the calendar */
    box-shadow: 0 0 5px rgba(0,0,0,0.3);  /* Optional: Add shadow for better visibility */
  }
  
  .react-datepicker__header {
    background-color: #f0f0f0;  /* Change header background color if needed */
  }
  
  .react-datepicker__day, .react-datepicker__day-name {
    color: #333;  /* Text color for days */
  }
  